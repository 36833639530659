import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import heroImage from '../../../assets/hero-image.svg';

import './HeroSection.scss';

const HeroSection = () => {
    const navigate = useNavigate();
    
    return (
        <Container fluid className='hero-container'>
            <Container>
                <Row>
                    <Col className='hero-header-container' lg={5}>
                        <div>
                            <h1 className='hero-main-header text-lg-start'>Custom <span className='gradient-text-blue'>Web and Data Solutions</span> for You and Your Business</h1>
                            <p className='hero-subtext text-lg-start mt-4'><span className='gradient-text-blue'>Hand-coded websites</span> for businesses and personal profiles, plus custom <span className='gradient-text-blue'>data</span> and <span className='gradient-text-blue'>automation solutions</span>, all tailored to your needs with a focus on superior results.</p>
                            <Button className='hero-button mt-3 float-lg-start fw-bold' size='lg' onClick={() => navigate('/contact')}>Get Started</Button>
                        </div>
                    </Col>
                    <Col className='hero-graphic-container' lg={7}>
                        <img src={heroImage} alt='Computer and phone graphic' className='hero-graphic' />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default HeroSection;
