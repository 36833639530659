import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import PriceCard from '../../Shared/PriceCard/PriceCard';

import './PricesSection.scss';

const PricingSection = ({isInverted}) => {
    const navigate = useNavigate();

    const priceCards = [
        {
            title: 'Profile Site',
            subtitle: <span className='price-text fw-bold'>$1,000</span>,
            monthlyText: 'Then $15/month',
            summary: 'Sleek single-page site to showcase your resume and portfolio.',
            listItems: [
                {
                    header: 'Professional',
                    text: 'Single-page design for your portfolio.'
                },
                {
                    header: 'Responsive',
                    text: 'Looks great on all devices.'
                },
                {
                    header: 'Customizable',
                    text: 'Highlight your skills and experience.'
                }
            ]
        },
        {
            title: 'Small Business Site',
            subtitle: <span className='price-text fw-bold'>$4,500</span>,
            monthlyText: 'Then $50/month',
            summary: 'Custom 4-5 page site, mobile-friendly and tailored to your needs.',
            listItems: [
                {
                    header: 'Complete Presence',
                    text: 'Home, About, Services, Portfolio, Contact.'
                },
                {
                    header: 'Custom Design',
                    text: 'Reflects your brand.'
                },
                {
                    header: 'Service Highlights',
                    text: 'Detailed descriptions and testimonials.'
                }
            ]
        },
        {
            title: 'Custom Automation Project',
            subtitle: <><span className='price-text fw-bold'>$100+</span><span className='fs-3'>/hour</span></>,
            monthlyText: 'Pricing varies by project',
            summary: 'Boost productivity with tailored automation scripts for data tasks.',
            listItems: [
                {
                    header: 'Automation',
                    text: 'Streamline repetitive tasks.'
                },
                {
                    header: 'Data Collection',
                    text: 'Web scraping and API integration.'
                },
                {
                    header: 'Optimization',
                    text: 'Improve efficiency, reduce errors.'
                }
            ]
        }
    ];

    return (
        <Row className={`prices-subsection mt-lg-5 mt-sm-3 ${isInverted ? 'prices-subsection-inverted' : ''}`}>
            <Container className='my-5'>
                <Row className='text-center justify-content-center'>
                    <Col lg={10}>
                        <Row className='justify-content-center'>
                            {priceCards.map(({ title, subtitle, summary, listItems, monthlyText, startingText }, i) => (
                                <Col key={i} lg={4} className='mb-lg-4'>
                                    <Fade direction='up' triggerOnce={true} delay={i * 150}>
                                        <div className='prices-section-card-container' onClick={() => navigate('/contact')}>
                                            <PriceCard title={title} subtitle={subtitle} monthlyText={monthlyText} startingText={startingText} summary={summary} listItems={listItems}  />
                                        </div>
                                    </Fade>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

PricingSection.propTypes = {
    isInverted: PropTypes.bool.isRequired
};

export default PricingSection;
