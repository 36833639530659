import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';

import './ServicesPage.scss';

import TopNavSubpage from '../Shared/TopNavSubpage/TopNavSubpage';
import Footer from '../Shared/Footer/Footer';
import PricingSection from '../Shared/PricesSection/PricesSection';

import coompanySiteImg from '../../assets/undraw_website_ij0l.svg';
import personalSiteImg from '../../assets/undraw_personal_site_re_c4bp.svg';
import dataImg from '../../assets/undraw_data_trends_re_2cdy.svg';
import responsiveSvg from '../../assets/responsive-symbol.svg';
import speedSvg from '../../assets/speedometer-svgrepo-com.svg';
import seoSvg from '../../assets/seo-svgrepo-com.svg';
import networkSvg from '../../assets/network-3-1116-svgrepo-com.svg';
import reportingSvg from '../../assets/analytics-svgrepo-com.svg';
import workflowSvg from '../../assets/workflow-svgrepo-com.svg';

const ServicesPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    const getStartedLink = (
        <a className='fs-5 action-link' href='/contact'><strong>Get Started Today</strong></a>
    );

    const servicesSummaries = [
        {
            title: 'Company Websites',
            description: [
                'Elevate your small business with a custom website designed to captivate and convert. Every site is meticulously crafted to align with your brand’s identity, ensuring a seamless user experience and a striking online presence.',
                'From concept to deployment, the focus is on delivering a solution that not only meets but exceeds expectations, making it easier for your business to stand out in a competitive market.'
            ],
            image: coompanySiteImg,
            alt: 'Website graphic'
        },
        {
            title: 'Personal Profile Websites',
            description: [
                'Transform your resume or portfolio into a powerful showcase with a personalized single-page website. This sleek, responsive design highlights your skills and achievements, presenting a professional image that grabs attention and sets you apart from the crowd.',
                'Perfect for job seekers and freelancers looking to make a memorable impression and effectively communicate their unique value.'
            ],
            image: personalSiteImg,
            alt: 'Personal profile website graphic'
        },
        {
            title: 'Data & Automation Solutions',
            description: [
                'Improve your efficiency with custom-built automation and data solutions. Whether it’s streamlining repetitive tasks or integrating data from diverse sources, my tailored strategies will transform your workflow.',
                'Ideal for businesses and professionals seeking to optimize operations and enhance productivity, these solutions will help you focus on what truly matters.'
            ],
            image: dataImg,
            alt: 'Data trends graphic'
        }
    ];

    const expertiseSummaries = [
        {
            header: 'Web Development',
            summaries: [
                {
                    header: 'Responsive Design',
                    subHeader: 'Adaptable and Accessible',
                    summary: 'I design and develop websites that look great and function well on all devices. Whether viewed on a desktop, tablet, or smartphone, your site will provide an optimal user experience across all screen sizes.',
                    imageProps: {src: responsiveSvg, alt: 'Responsive design'}
                },
                {
                    header: 'Performance Enhancement',
                    subHeader: 'Fast and Efficient',
                    summary: 'I focus on enhancing your website’s speed and performance, ensuring quick load times and a smooth user experience. This includes caching strategies, image optimization, and code minification.',
                    imageProps: {src: speedSvg, alt: 'Performance Enhancement'}
                },
                {
                    header: 'Search Engine Optimization',
                    subHeader: 'Boost Your Visibility',
                    summary: 'I implement comprehensive SEO strategies to enhance your website’s search engine ranking. From keyword research to on-page optimization, I ensure your site is optimized to attract and retain more visitors.',
                    imageProps: {src: seoSvg, alt: 'SEO Optimization'}
                }
            ]
        },
        {
            header: 'Data & Automation',
            summaries: [
                {
                    header: 'Data Integration',
                    subHeader: 'Seamless Connections',
                    summary: "I develop solutions for integrating data from multiple sources into a cohesive system. Whether it's combining data from various APIs or syncing databases, I ensure smooth and reliable data flow.",
                    imageProps: {src: networkSvg, alt: 'Data Integration'}
                },
                {
                    header: 'Custom Reporting',
                    subHeader: 'Actionable Insights',
                    summary: 'I create tailored reporting tools that provide actionable insights into your data. From automated dashboards to scheduled reports, I deliver solutions that help you make informed decisions.',
                    imageProps: {src: reportingSvg, alt: 'Custom Reporting'}
                },
                {
                    header: 'Workflow Automation',
                    subHeader: 'Streamline Operations',
                    summary: 'I design workflows that automate repetitive business processes, improving efficiency and reducing manual errors. Custom scripts and tools will streamline your operations and save valuable time.',
                    imageProps: {src: workflowSvg, alt: 'Workflow Automation'}
                }
            ]
        }
    ];

    const serviceSummarySection = (
        <Col lg={8} className='services-summaries-container'>
            {servicesSummaries.map(({ title, description, image, alt }, i) => (
                <Fade direction='up' triggerOnce={true} key={i}>
                    <Row className={`content-container-row py-5 ${i % 2 !== 0 ? 'flex-lg-row-reverse' : ''}`}>
                        <Col lg={6} className='d-flex justify-content-center'>
                            <Row className='services-image-container'>
                                <img src={image} alt={alt} className='img-fluid' />
                            </Row>
                        </Col>
                        <Col lg={6} className='d-flex justify-content-center'>
                            <Row className='text-lg-start'>
                                <h2>{title}</h2>
                                {description.map((paragraph, j) => (
                                    <p key={j}>
                                        {paragraph}
                                        <br />
                                    </p>
                                ))}
                                <p>{getStartedLink}</p>
                            </Row>
                        </Col>
                    </Row>
                </Fade>
            ))}
        </Col>
    );

    return (
        <>
            <TopNavSubpage activePage={'Services'} />
            <Container className='services-page-main-container light-background-gradient' fluid>
                <Row className='services-page-sub-section justify-content-center'>
                    <Row>
                        <Fade direction='up' triggerOnce={true}>
                            <h1>Crafting Impactful Digital Experiences for Business and Personal Growth</h1>
                        </Fade>
                    </Row>
                    {serviceSummarySection}
                </Row>
                <Row className='justify-content-center'>
                    <Col lg={8}>
                        <Fade direction='up' triggerOnce={true}>
                            <h2>My Digital Solutions Expertise</h2>
                        </Fade>
                        {expertiseSummaries.map(({header, summaries}, i) => (
                            <Row key={i} className='justify-content-center mt-5'>
                                <Fade direction='up' triggerOnce={true}>
                                    <h4 className='mb-4'>{header}</h4>
                                </Fade>
                                {summaries.map(({header, subHeader, summary, imageProps}, j) => (
                                    <Col key={j} md={4} className='text-start'>
                                        <Fade direction='up' triggerOnce={true} delay={j * 150}>
                                            <div className='services-summary-container'>
                                                <img className='services-summary-img mb-3' src={imageProps.src} alt={imageProps.alt} />
                                                <h5>{header}</h5>
                                                <h6>{subHeader}</h6>
                                                <p className='mt-3'>{summary}</p>
                                            </div>
                                        </Fade>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                    </Col>
                </Row>
                <PricingSection isInverted={true} />
            </Container>
            <Footer />
        </>
    );
}

export default ServicesPage;
