import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PagesProvider from './Context/PagesProvider';

import LandingPage from './Components/LandingPage/LandingPage';
import AboutPage from './Components/AboutPage/AboutPage';
import ServicesPage from './Components/ServicesPage/ServicesPage';
import ContactPage from './Components/ContactPage/ContactPage';
import Terms from './Components/Terms/Terms';
import Privacy from './Components/Privacy/Privacy';

import './App.css';

function App() {
    return (
        <PagesProvider>
            <Router>
                <div className='App'>
                    <Routes>
                        <Route exact path='/' Component={LandingPage} />
                        <Route path='/about' Component={AboutPage} />
                        <Route path='/services' Component={ServicesPage} />
                        <Route path='/contact' Component={ContactPage} />
                        <Route path='/terms-of-service' Component={Terms} />
                        <Route path='/privacy-policy' Component={Privacy} />
                    </Routes>
                </div>
            </Router>
        </PagesProvider>
    );
}

export default App;
