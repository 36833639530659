import React from 'react';
import { Container, Col, Card, ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './PriceCard.scss';

const PriceCard = ({title, subtitle, monthlyText, summary, listItems}) => {
    return (
        <Card className='shadow-sm card-container price-card'>
            <Card.Body className='d-flex flex-column'>
                <Card.Title as='h4' className='fs-4 price-card-header'>{title}</Card.Title>
                <div className='price-container'>
                    <Card.Subtitle className='gradient-text-blue'>{subtitle}</Card.Subtitle>
                    <div className='price-monthly-text gradient-text-blue'>{monthlyText}</div>
                </div>
                <Card.Text className='price-card-summary text-center'>
                    {summary}
                </Card.Text>
                <Container className='d-flex justify-content-center flex-grow-1 p-0'>
                    <Col>
                        <ListGroup className='text-start price-card-list-group card-list-group'>
                            {listItems.map(({header, text}, i) => (
                                <ListGroup.Item key={i}>
                                    <h5 className='item-title text-blue fw-bold'>{header}</h5>
                                    <p className='item-subtitle'>{text}</p>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Container>
            </Card.Body>
        </Card>
    );
};

PriceCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.element.isRequired,
    monthlyText: PropTypes.string,
    summary: PropTypes.string.isRequired,
    listItems: PropTypes.array.isRequired
};

export default PriceCard;
