import React, { useState } from 'react';
import { Form, Col, Button, Spinner, Container, Alert, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import emailjs from 'emailjs-com';
import * as yup from 'yup';

import mailImg from '../../../assets/undraw_envelope_re_f5j4.svg';

import './ContactForm.scss';

const userId = process.env.REACT_APP_EMAILJS_USER_ID;
const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

const ContactForm = () => {
    const [formStatus, setFormStatus] = useState({ success: null, message: '' });
    const [formVisible, setFormVisible] = useState(true);

    const fields = [
        {
            name: 'Name',
            type: 'text',
            placeholder: 'Name',
            validation: yup.string().required('Name is required'),
        },
        {
            name: 'Email',
            type: 'email',
            placeholder: 'Email',
            validation: yup
                .string()
                .email('Invalid email address')
                .required('Email is required'),
        },
        {
            name: 'Phone',
            type: 'text',
            placeholder: 'Phone (Optional)',
            validation: yup
                .string()
                .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        },
        {
            name: 'Message',
            type: 'textarea',
            placeholder: 'Type your message here',
            validation: yup
                .string()
                .max(500, 'Max character count 500')
                .required('Message is required'),
        },
    ];

    const schema = yup.object().shape(
        fields.reduce((acc, field) => {
            acc[field.name] = field.validation;
            return acc;
        }, {})
    );

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const form = document.getElementById('contact-form'); 

        emailjs.sendForm(serviceId, templateId, form, userId).then((result) => {
            setFormStatus({ success: true, message: 'Message sent successfully!' });
            resetForm();
            setFormVisible(false);
        }, (error) => {
            setFormStatus({ success: false, message: 'Failed to send message. Please try again.' });
        }).finally(() => {
            setSubmitting(false);
        });

        // For testing
        // setTimeout(() => {    
        //     if (Math.floor(Math.random() * 10) + 1 > 5) {
        //         setFormStatus({ success: true, message: 'Message sent successfully (mocked)!' });
        //         resetForm();
        //         setFormVisible(false);
        //     } else {
        //         setFormStatus({ success: false, message: 'Failed to send message (mocked). Please try again.' });
        //     }

        //     setSubmitting(false); 
        // }, 1000);
    };

    return (
        <Container className='contact-form-container'>
            {formVisible ? (
                <>
                    <h2 className='text-center mb-1'>Let's <span className='text-blue'>Connect</span></h2>
                    <Formik
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                        initialValues={fields.reduce((acc, field) => {
                            acc[field.name] = '';
                            return acc;
                        }, {})}
                        validateOnChange={true}
                        validateOnBlur={false}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                            <Form id='contact-form' noValidate onSubmit={handleSubmit}>
                                {fields.map((field) => (
                                    <Form.Group as={Col} controlId={`form${field.name}`} key={field.name}>
                                        <Form.Control
                                            as={field.type === 'textarea' ? 'textarea' : 'input'}
                                            type={field.type !== 'textarea' ? field.type : undefined}
                                            placeholder={field.placeholder}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleChange}
                                            isInvalid={!!errors[field.name] && touched[field.name]}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors[field.name]}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                ))}
                                <Button className='d-block mx-auto mt-3' type='submit' disabled={isSubmitting || !Object.keys(errors).length === 0}>
                                    {isSubmitting ? (
                                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    {formStatus.message && (
                        <Alert variant='danger' className='mt-3 text-center'>
                            {formStatus.message}
                        </Alert>
                    )}
                </>
            ) : (
                <div className='success-message'>
                    <Row>
                        <img className='p-5' src={mailImg} alt='Envelope'></img>
                    </Row>
                    <Row>
                        <h2 className='text-center'>Thank you!</h2>
                        <p className='text-center'>Your message has been received. We will get back to you as soon as possible.</p>
                    </Row>
                </div>
            )}
        </Container>
    );
};

export default ContactForm;
