import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import Footer from '../Shared/Footer/Footer';

const Terms = () => {
    const termsData = [
        {
          title: 'Introduction',
          content: 'Welcome to Colligan Tech Solutions LLC ("Company," "we," "our," "us"). By accessing or using our website www.colligantechsolutions.com (the "Site") or our services, you agree to comply with and be bound by the following terms and conditions (the "Terms of Service"). If you do not agree with these Terms, please do not use our Site or services.',
        },
        {
          title: 'Services',
          content: 'We provide web development, software development, and related services. The specific details and scope of services will be outlined in a separate agreement.',
        },
        {
          title: 'User Responsibilities',
          content: 'You agree to use our Site and services for lawful purposes only. You are responsible for all activities that occur under your account.',
        },
        {
          title: 'Intellectual Property',
          content: 'All content and materials on our Site, including text, graphics, logos, and software, are the property of Colligan Tech Solutions LLC or its licensors and are protected by intellectual property laws.',
        },
        {
          title: 'Limitation of Liability',
          content: 'To the fullest extent permitted by law, Colligan Tech Solutions LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Site or services.',
        },
        {
          title: 'Termination',
          content: 'We reserve the right to terminate or suspend your access to our Site or services at our sole discretion, without notice, for any reason, including a violation of these Terms.',
        },
        {
          title: 'Changes to Terms',
          content: 'We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our Site. Your continued use of the Site or services after such changes constitutes your acceptance of the new Terms.',
        },
        {
          title: 'Governing Law',
          content: 'These Terms shall be governed by and construed in accordance with the laws of the United States of America, without regard to its conflict of law principles.',
        },
        {
          title: 'Contact Us',
          content: 'If you have any questions about these Terms of Service, please contact us at mcolligan@colligantechsolutions.com.',
        },
    ];
      
    return (
        <>
            <Container fluid className='py-5 dark-background-gradient'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <Card className='bg-transparent border-0'>
                                <Card.Body>
                                    <Card.Title as='h2' className='mb-4 text-white fs-1'>Terms of Service</Card.Title>
                                    <div className='mb-4'>
                                        <Card.Text className='text-blue fw-bold mb-0'>Effective Date</Card.Text>
                                        <Card.Text className='text-white'>August 6, 2024</Card.Text>
                                    </div>
                                    {termsData.map(({title, content}, i) => (
                                        <div key={i} className='mb-4'>
                                            <Card.Text className='text-blue fw-bold mb-0 text-lg-start'>{title}</Card.Text>
                                            <Card.Text className='text-white text-start'>{content}</Card.Text>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Footer />
        </>
    );
};

export default Terms;
