import React from 'react';
import PropTypes from 'prop-types';

import './TopNavSubpage.scss';

import TopNav from '../TopNav/TopNav';

const TopNavSubpage = ({activePage}) => {
    return (
        <div className='upper-page-container'>
            <TopNav activePage={activePage} />
            <div className='page-header'>{activePage}</div>
        </div>
    );
}

TopNav.propTypes = {
    activePage: PropTypes.string.isRequired
};

export default TopNavSubpage;
