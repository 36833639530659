import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import Footer from '../Shared/Footer/Footer';

const Privacy = () => {
    const privacyData = [
        {
          title: 'Introduction',
          content: 'Colligan Tech Solutions LLC ("Company," "we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website www.colligantechsolutions.com (the "Site") and use our services.',
        },
        {
          title: 'Information We Collect',
          content: [
            'Personal Information: We may collect personal information that you voluntarily provide to us, such as your name, email address, and any other contact details.',
            'Usage Data: We collect information about how you interact with our Site, including IP addresses, browser types, pages viewed, and the dates and times of visits.',
            'Cookies and Tracking Technologies: We use cookies and similar technologies to collect information about your browsing activities. You can control cookie settings through your browser.'
          ],
        },
        {
          title: 'Use of Information',
          content: [
            'Provide and improve our services',
            'Communicate with you about our services and promotions',
            'Analyze usage trends and monitor the effectiveness of our marketing efforts',
          ],
        },
        {
          title: 'Sharing of Information',
          content: 'We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in operating our Site and services, subject to confidentiality agreements.',
        },
        {
          title: 'Data Security',
          content: 'We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.',
        },
        {
          title: 'Third-Party Links',
          content: 'Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of such third parties.',
        },
        {
          title: 'Your Choices',
          content: 'You may opt out of receiving promotional emails from us by following the unsubscribe instructions in those emails. You may also disable cookies through your browser settings.',
        },
        {
          title: 'Changes to This Policy',
          content: 'We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our Site. Your continued use of the Site or services after such changes constitutes your acceptance of the new Privacy Policy.',
        },
        {
          title: 'Contact Us',
          content: 'If you have any questions or concerns about this Privacy Policy, please contact us at mcolligan@colligantechsolutions.com.',
        },
    ];

    return (
        <>
            <Container fluid className='py-5 dark-background-gradient'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <Card className='bg-transparent border-0'>
                                <Card.Body>
                                    <Card.Title as='h2' className='mb-4 text-white fs-1'>Privacy Policy</Card.Title>
                                    <div className='mb-4'>
                                        <Card.Text className='text-blue fw-bold mb-0'>Effective Date</Card.Text>
                                        <Card.Text className='text-white'>August 6, 2024</Card.Text>
                                    </div>
                                    {privacyData.map(({title, content}, i) => (
                                        <div key={i} className='mb-4'>
                                            <Card.Text className='text-blue fw-bold mb-0 text-lg-start'>{title}</Card.Text>
                                            {Array.isArray(content) ? (
                                            content.map((item, j) => (
                                                <Card.Text key={j} className='text-white text-lg-start mb-1'>&bull; {item}</Card.Text>
                                            ))
                                            ) : (
                                            <Card.Text className='text-white text-start'>{content}</Card.Text>
                                            )}
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Footer />
        </>
        
    );
};

export default Privacy;
