import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import PropTypes from 'prop-types';

import headShot from '../../../assets/headshot.png';

import './AboutSection.scss';

const AboutSection = ({learnMoreLink}) => {
    const aboutSectionSummary = (
        <p className='about-subsection-summary text-white mb-5'>
            Hi, I'm Michael Colligan, the founder of Colligan Tech Solutions. I specialize in creating custom web development and software engineering solutions. With a passion for technology and years of experience, my mission is to deliver personalized and high-quality services that meet your unique needs. Whether you need a responsive website, a custom software solution, or data analysis tools, I'm here to help you succeed.&nbsp;
            {learnMoreLink}
        </p>
    );

    return (
        <Row className='site-subsection about-landing-section-container d-flex justify-content-center align-items-center'>
            <Row className='about-subsection-main-section d-flex justify-content-center align-items-center'>
                <Col lg={6}>
                <Fade direction='up' triggerOnce={true}>
                    <h2 className='landing-subheader'>About Me</h2>
                </Fade>
                </Col>
            </Row>
            <Row className='mt-lg-5 justify-content-center about-section-image-row'>
                <Col lg={6}>
                    <Fade direction='up' triggerOnce={true}>
                        {aboutSectionSummary}
                   </Fade>
                   <Fade className='headshot-fade' direction='up' triggerOnce={true}>
                        <div className='headshot-container pb-lg-1'>
                            <div className='image-container'>
                                <img className='headshot-image' src={headShot} alt='Michael Colligan headshot' />
                                <div className='tint-overlay'></div>
                            </div>
                            <p className='text-white fs-4 mb-0 mt-2'>Michael Colligan</p>
                            <p className='text-blue fs-6 fw-bolder'>Founder/Developer</p>
                        </div>
                   </Fade>
                </Col>
            </Row>
        </Row>                  
    );
}

AboutSection.propTypes = {
    learnMoreLink: PropTypes.element.isRequired
};


export default AboutSection;