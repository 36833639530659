import React from 'react';
import PagesContext from './PagesContext';

const PagesProvider = ({children}) => {
    const pages = [
        { title: 'Home', target: '/' },
        { title: 'About', target: '/about' },
        { title: 'Services', target: '/services' },
        // { title: 'Portfolio', target: '/portfolio' },
        { title: 'Contact', target: '/contact' }
    ];

    return (
        <PagesContext.Provider value={pages}>
            {children}
        </PagesContext.Provider>
    );
};

export default PagesProvider;
