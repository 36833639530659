import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav, Image, Offcanvas } from 'react-bootstrap';
import { BsChevronRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { usePages } from '../../../Context/PagesContext';
import { Fade } from 'react-awesome-reveal';
import PropTypes from 'prop-types';

import logo from '../../../assets/mainLogo_no_background.png';
import logoInverted from '../../../assets/mainLogo_no_background_inverted.png';

import './TopNav.scss';

const TopNav = ({ activePage }) => {
    const pages = usePages();
    const [scrolled, setScrolled] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const navigate = useNavigate();

    const handleScroll = () => {
        setScrolled(window.scrollY > 1);
    };

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const handleOffcanvasShow = () => setShowOffcanvas(true);

    const handleNavLinkClick = (target) => {
        setShowOffcanvas(false); 
        navigate(target); 
    };

    return (
        <>
            <Navbar className={`transition-navbar ${scrolled ? 'scrolled' : 'top'}`} variant={scrolled ? 'light' : 'dark'} expand='lg' fixed='top'>
                <Container>
                    <Navbar.Brand onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); navigate('/'); }}>
                        <Image className='logo-image' src={scrolled ? logoInverted : logo} alt='Colligan Tech Solutions LLC Logo' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='offcanvasNavbar' onClick={handleOffcanvasShow} className='d-lg-none' />
                    <Navbar.Collapse id='basic-navbar-nav' className={`justify-content-end ${!scrolled && 'navbar-collapse-bg-fixed'} d-none d-lg-flex`}>
                        <Nav className='top-nav-menu'>
                            {pages.map(({title, target}, i) => (
                                <Nav.Link key={i} className={`fs-5 ${i !== pages.length - 1 ? 'me-lg-3' : ''} ${activePage === title ? 'nav-link-active' : ''}`} href={target}>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <BsChevronRight className='nav-icon top-nav-link-icon' />
                                        <span className='nav-link-text'>{title}</span>
                                    </div>
                                </Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Offcanvas show={showOffcanvas} onHide={handleOffcanvasClose} placement='end' className='d-lg-none'>
                <Offcanvas.Header className='offcanvas-close-button fs-3' closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='mobile-menu-container'>
                        <Fade direction='right' cascade duration={175}>
                            {pages.map(({ title, target }, i) => (
                                <div key={i} className={`nav-link-mobile text-end fs-3 ${i !== pages.length - 1 ? 'mb-4' : ''} ${activePage === title ? 'nav-link-active-mobile' : ''}`} onClick={() => handleNavLinkClick(target)}>
                                    <span>{title}</span>
                                </div>
                            ))}
                        </Fade>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

TopNav.propTypes = {
    activePage: PropTypes.string.isRequired
};

export default TopNav;
