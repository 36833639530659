import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';

import './ContactPage.scss';

import TopNavSubpage from '../Shared/TopNavSubpage/TopNavSubpage';
import ContactForm from '../Shared/ContactForm/ContactForm';
import Footer from '../Shared/Footer/Footer';

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    return (
        <>
            <TopNavSubpage activePage={'Contact'} />
                <Container fluid className='contact-page-container light-background-gradient'>
                    <Container>
                        <Row className='d-flex justify-content-center contact-page-main-row'>
                            <Row className='d-flex justify-content-center'>
                                <Col lg={4}>
                                    <Fade direction='up' triggerOnce={true}>
                                        <ContactForm />
                                    </Fade>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center mt-4'>
                                <Col lg={4}>
                                    <Fade direction='up' triggerOnce={true}>
                                        <p className='contact-blurb-text'>Please fill out the form or email me directly at <a className='action-link' href='mailto:mcolligan@colligantechsolutions.com'><strong>mcolligan@ColliganTechSolutions.com</strong></a> and I'll get back to you as soon as possible.</p>
                                    </Fade>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </Container>
            <Footer />
        </>
    );
}

export default ContactPage;