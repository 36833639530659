import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import PropTypes from 'prop-types';

import webDevSvg from '../../../assets/web-development-symbol.svg'
import dataSvg from '../../../assets/data-processing-symbol.svg';

import './ServicesSection.scss';

const ServicesSection = ({learnMoreLink}) => {
    const servicesSummary = (
        <p className='services-summary mt-lg-5 text-white'>
            I design dynamic websites tailored for both small businesses and individuals, focusing on high performance and exceptional user experience. Additionally, I offer specialized data collection and automation solutions to optimize your business operations and boost productivity.&nbsp;
            {learnMoreLink}
        </p>
    );

    const servicesBlurbs = [
        {
            header: 'Custom Web Development',
            text: 'My custom web development services ensure that your website is built specifically to your business or personal needs. From initial concept to final deployment, every aspect is crafted to meet your goals.',
            imageProps: {src: webDevSvg, alt: 'Web design'}
        },
        {
            header: 'Automation Strategies',
            text: 'I specialize in creating custom scripts and automation to streamline your operations. From data collection to task automation, I provide efficient, tailored approaches to meet your specific needs.',
            imageProps: {src: dataSvg, alt: 'Data collection'}
        }
    ];

    return (
        <Row className='services-subsection site-subsection d-flex justify-content-center align-items-center'>
            <Row className='services-subsection-main-section d-flex justify-content-center align-items-center'>
                <Col lg={6}>
                    <Fade direction='up' triggerOnce={true}>
                        <h2 className='landing-subheader'>My Services</h2>
                    </Fade>
                    <Fade direction='up' triggerOnce={true}>
                        {servicesSummary}
                    </Fade>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center align-items-center text-white mt-lg-5'>
                <Col lg={10}>
                    <Row className='justify-content-center'>
                        {servicesBlurbs.map(({header, text, imageProps}, i) => (
                            <Col key={i} md={5} className='services-blurb-container text-start p-1'>
                                <Fade direction='up' triggerOnce={true} delay={i * 150}>
                                    <div className='services-blurb-content-container'>
                                        <img className='services-blurb-svg' src={imageProps.src} alt={imageProps.alt} />
                                        <h3>{header}</h3>
                                        <p className='services-blurb-paragraph'>{text}</p>
                                    </div>
                                </Fade>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Row>
    );
}

ServicesSection.propTypes = {
    learnMoreLink: PropTypes.element.isRequired
};

export default ServicesSection;