import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';

import TopNavSubpage from '../Shared/TopNavSubpage/TopNavSubpage';
import Footer from '../Shared/Footer/Footer';

import './AboutPage.scss';

import headShot from '../../assets/headshot.png';

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    return (
        <>
            <TopNavSubpage activePage={'About'} />
            <Container fluid className='about-page-content-container light-background-gradient'>
                <Container>  
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10} className='m-5'>
                            <Row>
                                <Col lg={4}>
                                    <Fade direction='up' triggerOnce={true}>
                                        <Row>
                                            <div>
                                                <img className='headshot-about-page mb-3' src={headShot} alt='Michael Colligan, founder'></img>
                                            </div>
                                        </Row>
                                    </Fade>
                                </Col>
                                <Col lg={8} className='text-start about-page-text-container'>
                                    <Row>
                                        <Fade direction='up' triggerOnce={true} delay={250}>
                                            <h1>Expertise in Web and Data Solutions</h1>
                                        </Fade>
                                        <Fade direction='up' triggerOnce={true} delay={500}> 
                                            <div>
                                                <p>Hi, I'm Michael Colligan, the founder of Colligan Tech Solutions. With a deep passion for technology and years of experience in the tech industry, I specialize in delivering custom web development and software engineering solutions that are tailored to your unique needs.</p>
                                                <p>I started my career as a data analyst, where I honed my skills in ensuring data accuracy, improving processes, and leading technical teams. My journey in the tech world began with a focus on data, but it wasn't long before I transitioned into software engineering, where I discovered my passion for creating user-friendly websites and developing automation tools. This combination of data-driven insight and software development expertise allows me to craft solutions that are both efficient and impactful.</p>
                                                <p>Now, as the founder of Colligan Tech Solutions, I bring together my diverse experience to offer high-quality, personalized services. Whether you need a responsive website, a custom software solution, or advanced data analysis tool, I'm here to help you achieve your goals. My mission is to provide innovative solutions that enhance your business operations and drive success.</p>
                                            </div>
                                        </Fade>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Row className='d-flex justify-content-center why-choose-me-container'>
                            <Col lg={6}>
                                <Fade direction='up' triggerOnce={true} delay={750}>
                                    <div className='why-choose-me-section'>
                                        <h2>Why Choose Me?</h2>
                                        <ul className='why-choose-me-list text-start'>
                                            <li><strong>Holistic Approach:</strong> My background in both data analysis and software engineering means I approach each project with a comprehensive understanding of how to best meet your needs.</li>
                                            <li><strong>Tailored Solutions:</strong> I believe in creating solutions that are specifically designed for your business, ensuring that you get exactly what you need to succeed.</li>
                                            <li><strong>Commitment to Quality:</strong> I’m dedicated to delivering work that not only meets but exceeds your expectations, ensuring that every project is completed to the highest standards.</li>
                                            <li><strong>Personalized Service:</strong> As the sole founder and operator of Colligan Tech Solutions, you’ll work directly with me, ensuring a personalized experience from start to finish.</li>
                                        </ul>
                                        <p><a className='action-link' href='/contact'><strong>Contact me today</strong></a> to get started on your next project and experience the difference that expertise and dedication can make.</p>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </Container>
            <Footer />
        </>
    );
};

export default AboutPage;
