import React, { useEffect } from 'react';

import TopNav from '../Shared/TopNav/TopNav';
import HeroSection from './HeroSection/HeroSection';
import ServicesSection from './ServicesSection/ServicesSection';
import AboutSection from './AboutSectionLanding/AboutSection';
import PricingSection from '../Shared/PricesSection/PricesSection';
import Footer from '../Shared/Footer/Footer';

import './LandingPage.scss';

const LandingPage = () => {    
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    const LearnMoreLink = ({href}) => {
        return (
            <a href={href} className='action-link fw-bold'>
                LEARN MORE
            </a>  
        );
    };

    return (
        <>
            <div className='dark-background-gradient'>
                <TopNav activePage='Home' />
                <HeroSection />
                <ServicesSection learnMoreLink={<LearnMoreLink href='/services' />} /> 
                <AboutSection learnMoreLink={<LearnMoreLink href='/about' />}/>
                <PricingSection isInverted={false} />
                <Footer />
            </div>
        </>
    );
    
};
  
export default LandingPage;